<template>
  <div class="work-content-left flex-column flex-start" >
    <div class="recent-projects margin-bottom flex-column">
      <div class="pro-content flex-column">
        <!-- icon 显示 -->
        <div class="flex-row pro-top">
          <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane
              :name="item.status + ''"
              v-for="(item) in tabList"
              :key="item.name">
              <span slot="label">{{ item.name }}
                <!-- 设置角标 -->
                <el-badge
                  v-if="item.count > 0"
                  :value="item.count"
                  class="badge-item"
                ></el-badge
                ></span>
            </el-tab-pane>
          </el-tabs>
        </div>

        <!-- 内容 -->
        <div class="project-show-container">
          <div
            class="project-show-content flex-row-between"
            v-for="(item, index) in showList"
            :key="index">
            <div class="flex-row proshow-left">
              <div class="flex-column proshow-text">
                <span class="proshow-title">{{ item.bizMasterinfo.masterinfo.sxmc || "" }}</span>
				<span class="proshow-dwmc">{{ item.bizMasterinfo.masterinfo.appcompany || "暂无单位信息" }}</span>
                <div class="flex-row flex-start proshow-notes">
                  <div>
                    业务流水号: <span>{{ item.bizMasterinfo.id || "" }}</span>
                  </div>
                  <div>
                    登文编号: <span>{{ item.bizMasterinfo.masterinfo.dwbh || "" }}</span>
                  </div>
                  <div >
                    接收时间: <span>{{ item.bizTbcx.createtime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="proshow-btn">
<!-- 			  <el-button
			    type="primary" size="small" icon="el-icon-edit"
			    @click="sendMessage">发表意见
			  </el-button> -->
			  <!-- Form -->
			  <el-button v-if="item.status == 0" type="text" @click="dialogFormVisible = true">发表意见</el-button>
			  <div v-else>已处理</div>
			  <el-dialog title="会计师意见处理" :visible.sync="dialogFormVisible">
			    <el-form :model="form">
				<el-form-item label="处理意见">
					<el-input type="textarea" v-model="form.clyj"></el-input>
				</el-form-item>
			    </el-form>
				<div>
					<!-- 上传文件代码地方 -->
				<el-upload
				ref="upload"
				action=""
				:auto-upload="true"
				:before-upload="field106BeforeUpload"
				:http-request="handleTestSuccess"
				:on-remove="handleRemove"
				>
				<el-button size="small" type="primary" icon="el-icon-upload">上传附件</el-button>
				</el-upload>
				</div>
			    <div slot="footer" class="dialog-footer">
			      <el-button @click="dialogFormVisible = false">取 消</el-button>
			      <el-button type="primary" @click="submitForm(item.id)">确 定</el-button>
			    </div>
			  </el-dialog>
            </div>
          </div>
        </div>

			<NoDataView
			  :dataState="
			     (showList == null || showList.length == 0)
			      ? 'noData'
			      : 'init'
			  "
			/>

      </div>
    </div>
    <!-- 页尾 -->
    <div class="pro-pagination">
	  <el-pagination
	   hide-on-single-page
	   style="margin-top:-38px;"
	   layout="total, prev, pager, next" background
	   :total="totalSizes"
	   :page-size="pagerSize"
	   @current-change="handleCurrentChange">
	   </el-pagination>
    </div>
  </div>
</template>

<script>
  import Store from '@/store/index'
  import {mapGetters} from "vuex";
  import httpApi from "../../../../utils/http";
  import NoDataView from "@/components/nodata-view";
  export default {
    components: {
		NoDataView
    },
    data() {
      return {
		fileList:[],
        pagerSize: 4, // 每页显示多少条数据
        totalSizes: 0, // 总条数
        currentPage: 0,
		activeName: "-1",
		uploadUrl: this.$httpApi.baseUrl + "/dev-api/fileManageApi/common/upload",
        tabList: [
		  {
		    name: "全部办件",
		    src: "el-icon-document",
		    status: "-1",
		  },
          {
            name: "待办件",
            src: "el-icon-document",
            status: "0",
          },
          {
            name: "已办件",
            src: "el-icon-document",
            status: "1",
          },
        ],
		showList:[],//获取列表
		dialogFormVisible:false,
        form: {
			clyj:"",

        },
        formLabelWidth: '120px',
		headers:{
			'WebAuthorization': Store.getters.getToken, // 让每个请求携带自定义token 请根据实际情况自行修改
			"appid": httpApi.appid,
			"appkey": httpApi.appkey,
			'Content-Type': 'application/json'
		}

      };
    },
    computed: {
      ...mapGetters({
        userInfo: "getUserInfo",
      }),
    },
    mounted() {
      this.loadData()
    },

    methods: {
	  sendMessage(){

	  },
	  // 上传附件
	  handleChange(file, fileList) {
	      console.log("上传文件了")
        console.log("fiel",file)
        console.log("fileList",fileList)
        this.fileList = fileList.slice(-3);
	  },

	  // 表单提交
	  submitForm(itemId){
		// let id = itemId
		// let params = {
		// 	"fileInfo":this.fileList,
		// 	"idea":this.form.clyj,
		// }
		// params.id = itemId,
		let formData = new FormData()
		// 测试用例的时候使用的文件列表
		// let JsFileList = [{"url":"/balocal-api/common/fileView/eae87674-55c2-4c24-842d-aa2d4e8b5df6","fileName":"bj.jpg","fileId":"eae87674-55c2-4c24-842d-aa2d4e8b5df6"}]
		// let toFile = JSON.stringify(JsFileList)
		let toFile = JSON.stringify(this.fileList)
		formData.append('fileInfo', toFile)
		formData.append('idea', this.form.clyj)
		formData.append('id', itemId)
		console.log("FileList",this.fileList)
		console.log("会计师发送参数",formData)
		this.$httpApi
		  .post("/dev-api/userBusiness/submitKjs", formData)
		  .then((res) => {
			console.log("会计师发表意见返回",res)
			if (res.code == 200) {
			  this.$message({
			    type: "success",
			    message: "操作成功",
			  });
			 this.dialogFormVisible = false
			  //刷新数据
			  this.loadData();
			}
		  })
		  .catch((e) => {
		    this.$message({
		      type: "warning",
		      message: "网络异常",
		    });
			this.dialogFormVisible = false
		  });
			// this.dialogFormVisible = false

	  },
		/**
       * 查询业务信息列表
       */
      loadData() {
        // 以服务的方式调用的 Loading 需要异步关闭
        let params = {
          pageNum: this.currentPage,
          pageSize: this.pagerSize,
        };

        let activeName = this.activeName
        if (activeName != "-1") {
          params.status = activeName
        }
        console.log("提交参数>>>", params);

        this.$httpApi
          .get("/dev-api/userBusiness/queryTbcxKjs", params)
          .then((res) => {
            console.log("获取办件的信息>>>", res);

            if (res.code == 200 && res.rows.length > 0) {
              this.showList = res.rows;
              this.totalSizes = res.total
            }
          })
          .catch((e) => {

          });
      },


      /* 分页切换时，更换显示的数组 */
      handleCurrentChange(index) {
        this.currentPage = index
        //清空显示的数据
        this.showList = []
          this.loadData()
      },
      /**
       * 切换
       */
      handleTabClick() {
        console.log("activeName:", this.activeName);
        this.currentPage = 0;
        //清空显示的数据
        this.showList = [];
        this.totalSizes = 0;
		this.loadData()
      },

	  /**
	   * 自定义文件上传
	   */
	  handleTestSuccess(param) {
	    //构建一个formData对象，因为这里要求表单类型的数据
	    // console.log('上传>>>', param)
	    let formData = new FormData()
	    let _file = param.file
	    formData.append('file', _file)

	    this.$httpApi
	      .post(`/dev-api/fileManageApi/common/upload`, formData)
	      .then((res) => {
	        console.log('res', res)
	        if (res.code === 200) {
				let file = {
				  status: "1", // 文件状态是否可用 0作废 1可用
				  fileId: res.fileId, // 文件ID
				  fileName: res.fileName, // 文件名称
				  name: res.fileName, // 文件名称
				  filepath: res.url, // 文件路径
				  ly: "", // 来源
				};
				this.fileList.push(file)

	        }
	      })
	      .catch((err) => {
	        this.$message.error('上传失败!')
	      });
	  },
	  // 删除文件的时候
	  handleRemove(){
	  },
	  field106BeforeUpload(file) {
	    let isRightSize = file.size / 1024 / 1024 < 2
	    if (!isRightSize) {
	      this.$message.error('文件大小超过 2MB')
	    }
	    return isRightSize
	  },
	  // 确认上传
	  submitUpload() {
	    this.$refs.upload.submit();
	  },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/com/theme.scss";

  .work-content-left {
    width: 100%;
    height: 100%;
    background: $color-fff;
    padding-bottom: 20px;

    .recent-projects {
      width: 100%;
      height: 100%;
      margin-bottom: 60px !important;

      .pro-content {
        width: 100%;
      }

      /* 搜索栏目 */
      .pro-search {
        padding: 0 50px;
      }

      .project-show-content {
        height: 100px;
        border-bottom: 1px solid #f1f1f1;

        .proshow-left {
          height: 100%;

          .proshow-status {
            width: 60px;
            font-size: $font-size12;
            color: #fff;
            background: #333;
            text-align: center;
            padding: 8px 0;
            margin: 0 10px;
            border-radius: 3px;
            position: relative;

            .arrow-bm {
              position: absolute;
              width: 0px;
              height: 0px;
              bottom: 0;
              transform: translate(calc(100%), 100%);
              border: 8px solid;
              border-color: #333 transparent transparent transparent;
            }
          }

          /* 不同状态不同颜色 */
          .status-pro0 {
            background: #3521a8;

            .arrow-bm {
              border-color: #3521a8 transparent transparent transparent;
            }
          }

          .status-pro1 {
            background: #ffb431;

            .arrow-bm {
              border-color: #ffb431 transparent transparent transparent;
            }
          }

          .status-pro2 {
            background: #ee0f0f;

            .arrow-bm {
              border-color: #ee0f0f transparent transparent transparent;
            }
          }

          .status-pro3 {
            background: #838117;

            .arrow-bm {
              border-color: #838117 transparent transparent transparent;
            }
          }

          .status-pro4 {
            background: #750413;

            .arrow-bm {
              border-color: #750413 transparent transparent transparent;
            }
          }

          .status-pro5 {
            background: #7e97ac;

            .arrow-bm {
              border-color: #7e97ac transparent transparent transparent;
            }
          }

          .status-pro7 {
            background: #5e974a;

            .arrow-bm {
              border-color: #5e974a transparent transparent transparent;
            }
          }

          .proshow-text {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: flex-start;

            .proshow-title {
				color: $color-title;
				font-size: $font-size16;
				font-weight: bold;
				margin-bottom: 10px;
            }

			.proshow-dwmc {
              color: $color-title;
              font-size: $font-size14;
              font-weight: bold;
              margin-bottom: 10px;
			}
            .proshow-notes {
              font-size: $font-size13;
              color: $color-notes;

              span {
                color: $color-title;
                font-weight: 400;
                margin-right: 40px;
              }
            }
          }
        }

        .proshow-btn {
          width: 220px;
          text-align: center;
          // background: chocolate;
          .other-btns {
            margin: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            // font-weight: 300px;
            color: #333;

            .copy-project {
              margin-left: 8px;
            }

            & div:hover {
              color: $col-theme;
            }
          }
        }
      }
    }
  }

  /* 当页公共 */
  .card-title {
    font-size: 20px;
    padding: 15px 20px;
    font-weight: 600;
    color: $color-title;
  }

</style>

<style lang="scss">
	.pro-pagination{
		width: 100%;

		display: flex;
		// justify-content: flex-end;
	}
  .pro-top {
    width: 100%;

    .el-tabs {
      .el-tabs__header {
        .el-tabs__nav {
          height: 50px;
          line-height: 50px;
        }

        .el-tabs__item {
          position: relative;
          height: 26px;
          // color:#2d81ff;
          font-family: Microsoft YaHei;
          font-size: 16px;
          font-weight: bold;
        }

        .el-tabs__active-bar {
          width: 81px;
          height: 3px;
          background-color: #2d81ff;
        }
      }
    }
  }

  .project-show-container {
    width: 900px;
    position: relative;
    min-height: 250px;
  }
</style>
